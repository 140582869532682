import React from "react";
import {
	Button,
	Card,
	CardBody,
	CardImg,
	CardTitle,
	Col,
	Container,
	Row,
} from "reactstrap";

import BossBattleBlitz from "../assets/images/BossBattleBlitz.png";
import ColorsOfSpringImage from "../assets/images/ColorsOfSpring.png";
import GloriousBattleRhapsodyImage from "../assets/images/GloriousBattleRhapsody.png";
import OceanRaidersOvertureImage from "../assets/images/OceanRaidersOverture.png";
import StarryNightSerenadeImage from "../assets/images/StarryNightSerenade.png";
import VictoriousFanfareImage from "../assets/images/VictoriousFanfare.png";

const PortfolioContent = () => {
	// Sample data, replace with actual data
	const featuredTunes = [
		{
			tuneId: "lthu2uei",
			title: "Glorious Battle Rhapsody",
			image: GloriousBattleRhapsodyImage,
		},
		{
			tuneId: "cd0d6744-0bdd-4b0d-9a21-bbc46b4c7c8e",
			title: "Colors of Spring",
			image: ColorsOfSpringImage,
		},
		{
			tuneId: "lthrpzzq",
			title: "Boss Battle Blitz",
			image: BossBattleBlitz,
		},
		{
			tuneId: "9e232f94-8210-42c4-924e-169d529a11b5",
			title: "Ocean Raiders Overture",
			image: OceanRaidersOvertureImage,
		},
		{
			tuneId: "76d42155-9f4e-480f-98bf-720f1fe789a3",
			title: "Starry Night Serenade",
			image: StarryNightSerenadeImage,
		},
		{
			tuneId: "5ed0af7c-1175-4194-bba5-229304e76dd1",
			title: "Victorious Fanfare",
			image: VictoriousFanfareImage,
		},
	];

	return (
		<Container className="mt-5">
			<h1>Featured Works</h1>
			<p>
				Explore some of the best compositions generated by OrchestrAI.
			</p>

			<Row className="justify-content-center" style={{ display: "flex" }}>
				{featuredTunes.map((tune) => (
					<Col
						key={tune.tuneId}
						xl={4}
						md={6}
						style={{ display: "flex" }}
					>
						{" "}
						<a
							href={`/tunes/${tune.tuneId}`}
							rel="noopener noreferrer"
							target="_blank"
							className="text-decoration-none text-dark"
						>
							<Card
								className="my-2 clickable-card"
								style={{ flex: 1 }}
							>
								<CardImg
									bottom
									// width="100%"
									src={tune.image}
									alt={tune.title}
									style={{
										maxHeight: "150px",
										objectFit: "cover",
										// only crop the bottom of the image
										objectPosition: "center top",
									}}
								/>
								<CardBody>
									<CardTitle tag="h5">{tune.title}</CardTitle>
									{/* <Button
										href={`/tunes/${tune.tuneId}`}
										className="primary-button"
										rel="noopener noreferrer"
										target="_blank"
									>
										View Tune{" "}
										<span className="icon-square flex-shrink-0">
											<i
												className={`bi bi-arrow-right`}
											/>
										</span>
									</Button> */}
								</CardBody>
							</Card>
						</a>
					</Col>
				))}
			</Row>
		</Container>
	);
};

export default PortfolioContent;
